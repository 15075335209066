import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as brandsActions from '../actions/brandsActionCreators';

const {API, ACTIONS} = constants;

export const fetchBrandsRequest = (params = {}) => RequestTools.ajax({url: API.BRANDS_PATH, params});
export const fetchBrandRequest = (id) => RequestTools.ajax({url: API.BRAND_PATH(id)});
export const fetchBrandRubricsRequest = (id) => RequestTools.ajax({url: API.BRAND_RUBRICS_PATH(id)});
export const fetchBrandProductsRequest = (params) => RequestTools.ajax({url: API.PRODUCTS_PATH, params});

export function * fetchPopularBrands() {
  try {
    const response = yield call(fetchBrandsRequest, {q: {popular: true}, per_page: 6});

    yield put(brandsActions.fetchPopularBrandsSuccess(response.data));
  } catch (e) {
    yield put(brandsActions.fetchPopularBrandsFailed());
  }
}

export function * fetchBrands() {
  try {
    const response = yield call(fetchBrandsRequest, {q: {popular: true}, per_page: 999});

    yield put(brandsActions.fetchBrandsSuccess(response.data));
  } catch (e) {
    yield put(brandsActions.fetchBrandsFailed());
  }
}

export function * fetchBrand(action) {
  try {
    const response = yield call(fetchBrandRequest, action.payload.id);

    yield put(brandsActions.fetchBrandSuccess(response.data));

    yield put(brandsActions.fetchBrandRubrics());

    yield put(brandsActions.fetchBrandProducts('hits'));
    yield put(brandsActions.fetchBrandProducts('stocks'));
    yield put(brandsActions.fetchBrandProducts('latests'));
  } catch (e) {
    yield put(brandsActions.fetchBrandFailed());
  }
}

export function * fetchBrandRubrics() {
  const {brandsState: {currentBrand}} = yield select();
  try {
    const response = yield call(fetchBrandRubricsRequest, currentBrand.id);

    yield put(brandsActions.fetchBrandRubricsSuccess(response.data));
  } catch (e) {
    yield put(brandsActions.fetchBrandRubricsFailed());
  }
}

export function * fetchBrandProducts(action) {
  const
    {brandsState: {currentBrand}} = yield select(),
    type = action.payload.type;
  try {
    const response = yield call(fetchBrandProductsRequest, {q: {brand_ids: [currentBrand.id], scopes: [type]}, page: 1, per_page: 5});

    yield put(brandsActions.fetchBrandProductsSuccess(type, response.data));
  } catch (e) {
    yield put(brandsActions.fetchBrandProductsFailed(type));
  }
}

function * brandsSaga() {
  yield takeEvery(ACTIONS.FETCH_POPULAR_BRANDS, fetchPopularBrands);
  yield takeEvery(ACTIONS.FETCH_BRANDS, fetchBrands);
  yield takeEvery(ACTIONS.FETCH_BRAND, fetchBrand);
  yield takeEvery(ACTIONS.FETCH_BRAND_RUBRICS, fetchBrandRubrics);
  yield takeEvery(ACTIONS.FETCH_BRAND_PRODUCTS, fetchBrandProducts);
}

export default brandsSaga;
