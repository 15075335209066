import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import './style.scss';
import './mobile.scss';
import {objectToUrlParams} from '../../tools/helpers';

const RubricsPreviewList = ({allUrl = null, rubrics, ...props}) => {
  return (
    <ul className='rubrics-list'>
      {
        rubrics.map((rubric) => {
          return (
            <li className='rubrics-list__item' key={rubric.id}>
              <Link to={constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, rubric.joined_path, props.filter ? objectToUrlParams(props.filter) : null)}>
                <span className='rubrics-list__item-bg' />
                <img src={rubric.image?.small} alt={rubric.image?.alt || rubric.title} />
                <span className='rubrics-list__item-title'>{rubric.title}</span>
              </Link>
            </li>
          )
        })
      }
    </ul>
  );
};

export const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(RubricsPreviewList);
