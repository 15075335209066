import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import * as cartActions from '../../actions/cartActionCreators';

import CartInfo from '../Cart/CartInfo';
import LargeSwitcher from '../LargeSwitcher';
import CustomSwitch from '../Switch';
import './style.scss';
import './mobile.scss';
import OrderUserForm from './UserForm';
import ChevronIcon from '../Icons/Chevron';
import MobilePaymentSelect from './Mobile/PaymentSelect';
import AddressForm from './AddressForm';
import MobileRecipientForm from './Mobile/RecipientForm';

const OrderWizard = (props) => {
  const
    {cartState, applicationState} = props,
    {is_mobile} = applicationState,
    [itemsForOrder, setItemsForOrder] = useState(cartState.info.itemsForOrder || []),
    [mobilePaymentOpen, setMobilePaymentOpen] = useState(false),
    [addressFormOpen, setAddressFormOpen] = useState(false),
    [recipientFormOpen, setRecipientFormOpen] = useState(false),
    deliveryOptions = [
      {label: 'Доставка', value: 0},
      {label: 'Самовывоз', value: 1},
    ],
    [orderData, setOrderData] = useState({
      pickup: 1,
      payment_type: 'Наличными',
      has_questions: false,
      subscribe: true,
      address: '',
      recipient: null
    }),
    paymentTypeOptions = [
      {label: 'Банковской картой онлайн', value: 'sbr'},
      {label: 'Наличными в офисе', value: 'Наличными'},
      {label: 'Выслать счет на почту', value: 'По счёту'},
    ],
    currentPaymentLabel = paymentTypeOptions.find(option => option.value === orderData.payment_type).label,
    {first_name, last_name, patronymic, email, phone} = orderData?.recipient || {},
    deliveryValid = orderData.pickup === 1 || (orderData.pickup === 0 && !!orderData.address.length),
    canSendOrder = ((email && email.length) || (phone && phone.length)) && deliveryValid,
    renderUserString = () => {
      return (
        <>
          <span className='text-600'>{[last_name, first_name, patronymic].join(' ')}</span>
          {
            phone ?
              `, ${phone}`
              : null
          }
          {
            email ?
              `, ${email}`
              : null
          }
        </>
      );
    },
    handleChange = (key) => (value) => {
      setOrderData({...orderData, [key]: value});
    };
  console.log(canSendOrder);
  useEffect(() => {
    const
      items1key = cartState.info.itemsForOrder.sort().join('_'),
      items2key = itemsForOrder.sort().join('_');
    if (items2key === items1key) { return; }

    setItemsForOrder(cartState.info.itemsForOrder);

  }, [cartState.info.itemsForOrder, setItemsForOrder])

  return (
    <div className='cart-page-content'>
      <div className='cart-page__col cart-page__col_1'>
        <div className='order-form'>
          <div className='order-form__section'>
            <AddressForm
              onChange={handleChange('address')}
              onClose={() => setAddressFormOpen(false)}
              open={addressFormOpen}
              mobile={is_mobile}
            />

            <LargeSwitcher options={deliveryOptions} onChange={handleChange('pickup')} value={orderData.pickup} />
            {
              is_mobile ?
                <div className='mobile-order-form__section'>
                  <div className='mobile-order-form__section-item'>
                    <button
                      className='mobile-order-form__section-item-left'
                      onClick={() => setAddressFormOpen(true)}
                    >
                      {
                        orderData.address ?
                          <div className='mobile-order-form__section-item-value'><span className='text-600'>{orderData.address}</span></div>
                          : null
                      }
                      <div className='mobile-order-form__section-item-label'>Доставка</div>
                    </button>
                    <ChevronIcon direction='right' />
                  </div>
                  <div className='mobile-order-form__section-item'>
                    <button
                      className='mobile-order-form__section-item-left'
                      onClick={() => setRecipientFormOpen(true)}
                    >
                      <div className='mobile-order-form__section-item-value'>
                        {
                          orderData.recipient ?
                            renderUserString()
                            : null
                        }

                      </div>
                      <div className='mobile-order-form__section-item-label'>Получатель</div>
                    </button>
                    <ChevronIcon direction='right' />
                  </div>
                  <MobileRecipientForm
                    onChange={handleChange('recipient')}
                    onClose={() => setRecipientFormOpen(false)}
                    open={recipientFormOpen}
                  />
                  <div className='mobile-order-form__section-item'>
                    <button
                      className='mobile-order-form__section-item-left'
                      onClick={() => setMobilePaymentOpen(true)}
                    >
                      <div className='mobile-order-form__section-item-value'><span className='text-600'>{currentPaymentLabel}</span></div>
                      <div className='mobile-order-form__section-item-label'>Способ оплаты</div>
                    </button>
                    <ChevronIcon direction='right' />
                  </div>
                  <MobilePaymentSelect
                    value={orderData.payment_type}
                    options={paymentTypeOptions}
                    onChange={handleChange('payment_type')}
                    onClose={() => setMobilePaymentOpen(false)}
                    open={mobilePaymentOpen}
                  />
                </div>
                : null
            }
          </div>
          {
            is_mobile ?
              null
              :
              <div className='order-form__section'>
                {
                  orderData.pickup === 0 ? // if pickup delivery
                    <div className='order-form__subsection'>
                      <div className='order-form__subsection-title'>
                        Доставка по адресу
                        <button
                          className='button-link'
                          onClick={() => setAddressFormOpen(true)}
                        >
                          {orderData.address ? 'Изменить' : 'Указать'}
                        </button>
                      </div>
                      {
                        orderData.address ?
                          <div className='order-form__subsection-content'>
                            {orderData.address}
                          </div>
                          : null
                      }
                    </div>
                    : null
                }
                <div className='order-form__subsection'>
                  <div className='order-form__subsection-title'>Получатель</div>
                  <div className='order-form__subsection-content'>
                    <OrderUserForm
                      data={orderData}
                      onChange={handleChange('recipient')}
                    />
                  </div>
                </div>
                <div className='order-form__subsection'>
                  <div className='order-form__subsection-title'>Способ оплаты</div>
                  <div className='order-form__subsection-content'>
                    <LargeSwitcher options={paymentTypeOptions} onChange={handleChange('payment_type')} value={orderData.payment_type} />
                  </div>
                </div>
              </div>
          }

          <div className='order-form__section'>
            <label className='flex gap-8'>
              <CustomSwitch checked={orderData.has_questions} onChange={handleChange('has_questions')} />
              У меня остались вопросы
            </label>
          </div>
          <div className='order-form__section'>
            <label className='flex gap-8'>
              <CustomSwitch checked={orderData.subscribe} onChange={handleChange('subscribe')} />
              Получать информацию о скидках и выгодных предложениях на электронную почту
            </label>
          </div>
        </div>
      </div>
      <div className='cart-page__col cart-page__col_2'>
        <CartInfo itemsForOrder={itemsForOrder} orderScreen orderParams={orderData} disableAction={!canSendOrder} />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({applicationState, cartState}) => {
    return {
      applicationState,
      cartState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(OrderWizard);
